import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          developer {
            name
            twitter
          }
        }
      }
    }
  `);

  const { developer } = data.site.siteMetadata;

  return (
    <footer className="my-12 text-center">
      © {new Date().getFullYear()}, Built with ❤️
      {` `}
      by{" "}
      <a
        href={`https://twitter.com/${developer.twitter}`}
        target="_blank"
        rel="noreferrer"
      >
        {developer.name}
      </a>
      .
    </footer>
  );
};

export default Footer;
